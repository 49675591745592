.geometry-effect {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000;
}

.shape {
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    animation: float-up 3s ease-out;
    opacity: 0;
}

@keyframes float-up {
    0% {
        transform: translateY(100vh) rotate(0deg) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateY(-100px) rotate(360deg) scale(2);
        opacity: 0;
    }
}