.summary-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.summary-container {
    background-color: var(--bg-color);
    border-radius: 15px;
    padding: 2rem;
    width: 90%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    max-width: 500px;
    min-width: 300px;
}

.summary-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: var(--main-color);
}

.summary-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.summary-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.summary-label {
    font-size: 1rem;
    color: var(--sub-color);
    margin-bottom: 0.5rem;
}

.summary-value {
    font-size: 2rem;
    font-weight: bold;
    color: var(--main-color);
}

.leaderboard-input-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
}

.summary-input {
    flex: 1;
    min-width: 0;
    height: 40px;
    padding: 0 10px;
    border: none;
    border-radius: 5px;
    background-color: #2c2c2c;
    color: #fff;
    font-size: 16px;
    box-sizing: border-box;
}

.summary-button {
    flex: 0 0 auto;
    height: 40px;
    padding: 0 15px;
    border: none;
    border-radius: 5px;
    background-color: var(--main-color);
    color: var(--bg-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    font-size: 16px;
    line-height: 40px;
    box-sizing: border-box;
    margin-top: -3px; /* Move the button up slightly */
}

.summary-input::placeholder {
    color: var(--sub-color);
}

.summary-button:hover {
    background-color: var(--caret-color);
}

.summary-button:disabled {
    background-color: var(--sub-color);
    cursor: not-allowed;
}

.summary-input:disabled {
    background-color: var(--sub-alt-color);
    color: var(--sub-color);
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .summary-container {
        width: 95%;
        padding: 1rem;
    }

    .summary-title {
        font-size: 1.5rem;
    }

    .summary-stats {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .summary-value {
        font-size: 1.5rem;
    }

    .leaderboard-input-group {
        flex-direction: column;
    }

    .summary-input,
    .summary-button {
        width: 100%;
        margin-top: 0.5rem;
    }
}